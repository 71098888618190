import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Response Code">
      <div className="headline">
        <h1 className="text-primary m-0">Response Code</h1>
      </div>
      <p>
        Liquid Platform uses conventional HTTP status codes to indicate the
        success or failure of an API request.
      </p>

      <h2 className="h3">HTTP Status Code</h2>
      <p>Refer to table below for description of HTTP status codes</p>

      <table className="table table-hover specification">
        <thead>
          <tr>
            <td>Status Code</td>
            <td>Description</td>
            <td>Case</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>200</td>
            <td>Ok</td>
            <td>Successful case</td>
          </tr>
          <tr>
            <td>400</td>
            <td>Bad Request</td>
            <td>
              <span>Request unacceptable</span>
              <p className="text-muted small">E.g. Invalid parameters</p>
            </td>
          </tr>
          <tr>
            <td>401</td>
            <td>Unauthorised</td>
            <td>
              <span>Not authorised to access</span>
              <p className="text-muted small">
                E.g. Accessing retricted data without proper permission
              </p>
            </td>
          </tr>
          <tr>
            <td>402</td>
            <td>Request Failed</td>
            <td>
              <span>Request failed to processed</span>
              <p className="text-muted small">
                E.g. Valid parameter sent but the request cannot be processed at
                the moment
              </p>
            </td>
          </tr>
          <tr>
            <td>404</td>
            <td>Not Found</td>
            <td>
              <span>Resource not found</span>
              <p className="text-muted small">
                E.g. Either URI or requested data cannot be found
              </p>
            </td>
          </tr>
          <tr>
            <td>500</td>
            <td>Internal Server Error</td>
            <td>
              <span>Request cannot be processed at the moment</span>
              <p className="text-muted small">
                E.g. An exception case happened over server side
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </DocumentLayout>
  )
}
